import React from 'react';
import { Button, Form, Container, Col, Row, Card } from 'react-bootstrap';
import Header from './Header/Header'
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import { history } from '../_helpers/history';
import {Trans} from "react-i18next";


class Login extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      lastname: '',
      city: '',
      cell_phone: '',
      year_old: '',
      demo: 1,
      submitted: false
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState(prevState => ({ ...prevState, submitted: true }));

    const { name, lastname, email, city, cell_phone, year_old,demo } = this.state;
    if (name && lastname && email && city && cell_phone && year_old) {
      this.props.registerUser({ name, lastname, email, city, cell_phone, year_old, demo });
    }
  }

  componentDidUpdate() {
    if (this.props.auth.token) {
      history.push('/video');
    }
  }

  render() {
    const { name, lastname, email, city, cell_phone, year_old, submitted } = this.state;
    const { auth } = this.props;

    return (
      <>
        <Header />
        <Container className="mt-3">
          <Row>
            <Col xs="6" className="m-auto">
              <Card>
                <Card.Header>
                  <Trans i18nKey="register.btn_sign_in"></Trans>

                </Card.Header>

                <Card.Body>
                  <Form onSubmit={this.handleSubmit}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>
                        <Trans i18nKey="register.name"></Trans>
                      </Form.Label>
                      <Form.Control type="text"  name="name" value={name} onChange={this.handleChange} />
                      {submitted && !name &&
                        <div className="label label-danger">name is required</div>
                      }
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>
                        <Trans i18nKey="register.lastname"></Trans>
                      </Form.Label>
                      <Form.Control type="text" name="lastname" value={lastname} onChange={this.handleChange} />
                      {submitted && !lastname &&
                        <div className="label label-danger">lastname is required</div>
                      }
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>
                        <Trans i18nKey="register.email"></Trans>
                      </Form.Label>
                      <Form.Control type="email"  name="email" value={email} onChange={this.handleChange} />
                      {submitted && !email &&
                        <div className="label label-danger">email is required</div>
                      }
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>
                        <Trans i18nKey="register.city"></Trans>
                      </Form.Label>
                      <Form.Control type="text"  name="city" value={city} onChange={this.handleChange} />
                      {submitted && !city &&
                        <div className="label label-danger">city is required</div>
                      }
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>
                        <Trans i18nKey="register.telephone"></Trans>
                      </Form.Label>
                      <Form.Control type="text"  name="cell_phone" value={cell_phone} onChange={this.handleChange} />
                      {submitted && !cell_phone &&
                        <div className="label label-danger">cell phone is required</div>
                      }
                    </Form.Group>

                    <Form.Group controlId="formBasicEmail">
                      <Form.Label>
                        <Trans i18nKey="register.year_old"></Trans>
                      </Form.Label>
                      <Form.Control type="text"  name="year_old" value={year_old} onChange={this.handleChange} />
                      {submitted && !year_old &&
                        <div className="label label-danger">year old is required</div>
                      }
                    </Form.Group>


                    <Button variant="primary" type="submit">
                      <Trans i18nKey="register.btn_sign_in"></Trans>
                    </Button>
                  </Form>

                    {auth.error && (
                      <Alert className='mt-4' variant='danger'>
                        {auth.error.message}
                      </Alert>
                    )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapDispatch = dispatch => ({
  signin: (body) => dispatch.auth.signin(body),
  registerUser: (body) => dispatch.auth.registerUser(body),
});

const mapState = state => ({
  auth: state.auth
});


export default connect(mapState, mapDispatch)(Login);
