import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import App from './components/App';
import './css/index.css';
import 'react-notifications/lib/notifications.css';
import 'bootstrap/dist/css/bootstrap.css';
import store from './store/store'

import {I18nextProvider} from "react-i18next";
import i18next from "i18next";

import common_en from './locales/en/translation.json'
import common_es_mx from './locales/es_mx/translation.json'
import common_es_ar from './locales/es_ar/translation.json'


i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: "en",                              // language to use
  resources: {
      en: {
        translation: common_en               // 'common' is our custom namespace
      },
      es_mx: {
        translation: common_es_mx
      },
      es_ar: {
        translation: common_es_ar
      },
  },
});

const root = (
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
    <App />

    </I18nextProvider>
  </Provider>
  );

ReactDOM.render(root, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
